<template>
  <el-container class="patroladd">
     
    <el-header class="box add bb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统参数配置</el-breadcrumb-item>
       <el-breadcrumb-item>模板分类列表</el-breadcrumb-item>
        <el-breadcrumb-item v-if="page.page_config_id">编辑模板分类</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增模板分类</el-breadcrumb-item>
    </el-breadcrumb>
        <div><el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="pt0">
    <el-form :inline="true"  status-icon ref="formInline"  :model="formInline"  class="demo-ruleForm">
      <el-row class="box mt20  f14">
         <el-col :span="24" class="patroladd-title bgcfbe tl mb20 pl15">监管分类</el-col>
        <el-col :span="22" class="tl mb20" :offset="1">
           <el-button v-for="(v,k) in list_btn1" :key="k" :class="activeClass1 ==k?'active':''" class=""  size="medium" @click="IsActive1(k,v.company_type_id,v.company_type_name)">{{v.company_type_name}}</el-button>  
         </el-col>
     </el-row>
        <el-row class="box mt20  f14">
         <el-col :span="24" class="patroladd-title bgcfbe tl mb20 pl15">主体类型</el-col>
        <el-col :span="22" class="tl mb20" :offset="1">
           <el-button v-for="(v,k) in list_btn" :key="k" :class="activeClass ==k?'active':''" class=""  size="medium" @click="IsActive(k,v.company_tag_id,v.company_tag_name)">{{v.company_tag_name}}</el-button>  
         </el-col>
     </el-row>
     <el-row class="box mt20  f14">
         <el-col :span="24" class="patroladd-title bgcfbe tl mb20 pl15">模板信息</el-col>
          <el-col :span="11" class="tl" >
        <el-form-item  class="rules w" label-width="28%" label="模板名称："  size="medium"  prop="page_config_name" :rules="[{required: true,message: '请输入模板名称', trigger: 'blur' }]">
           <el-input  style="width:100%" placeholder="请输入模板名称" v-model="formInline.page_config_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="mr10">
            <el-form-item size="medium" label-width="28%" label="模板类型：" prop="page_module_id" :rules="[{required: true,message: '请选择模板类型', trigger: 'blur' }]">
              <el-select v-model="formInline.page_module_id" clearable placeholder="请选择模板类型" @change="change(formInline.page_module_id)">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.page_module_id" :label="item.page_module_name" :value="String(item.page_module_id)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
     </el-row>
     <el-row class="box mt20  f14">
         <el-col :span="24" class="patroladd-title bgcfbe tl mb20 pl15">页面属性信息</el-col>
          <el-checkbox-group v-model="checkboxGroup1" @change="handleCheckedChange(checkboxGroup1)">
          <el-col v-for="(item,index) in list1" :key="index" :span="22" class="tl" :offset="1">
            <el-col :span="10" class="mr10">
           <el-checkbox  class="ml10 mb20"  :label="item.attr_en" :value="item.attr_en" :key="item.attr_en" border size="medium">{{item.attr_ch}}</el-checkbox>
            </el-col>
            <el-col  :span="10" class="mr10" :offset="1" >
             <el-form-item size="medium">
         <el-input placeholder="请输入属性值（0到1之间的一位小数，包括0和1）" @change="aaa(item.attr_en,item.attr_val)"  clearable v-model="item.attr_val" class="input-with-select">
            </el-input>
            </el-form-item>
        </el-col>
        </el-col>

        </el-checkbox-group>
     </el-row>
      <el-col :span="11" class="tl" :offset="1">
           <el-button  class=""  size="medium" type="primary" @click="submitForm('formInline')">提交</el-button>
        </el-col>
     
     </el-form>
     </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,objKeySort,session } from '../../../util/util'
let aa;
export default {
  data () {
    return {
      menu_list:'',
      activeClass:0,
      activeClass1:0,
      list_btn1:'',
       list_btn:'',
       checked1: true,
        checked2: false,
        checkboxGroup1: [],
        checkboxGroup2:[],
        list:[],
          statusOptions: [],
        formInline:{
            company_tag_id:'',
            company_type_id:'',
            page_config_name:'',
            page_module_name:'',
            company_tag_name:'',
            company_type_name:'',
            page_config_content:[],
            page_config_id:'',
            page_module_id:'',
        },
        page:{
            page_config_id:''
        },
        list1:[]
    }
  },
     created () {
           this.page.page_config_id=session.get('params').id?String(session.get('params').id):'';
      this.user = local.get('user');
            this.getModule()
        if(this.page.page_config_id){
this.getdate()
        }else{
           this.init(this.page)
            this.init1()
        }
     
  },
  methods: {
  
     init () {
        axios.get('/pc/page-attr/all', {page_module_id:this.formInline.page_module_id,state:'1'}).then((v) => {
          this.list1=v.data.page_attr_list;
            if(this.page.page_config_id){
              this.checkboxGroup1=[]
               this.list1.forEach(item=>{
               aa.forEach(element => { this.checkboxGroup1=this.checkboxGroup1.concat(element.attr_en)
               if(item.attr_en==element.attr_en){
                  item.attr_val=element.attr_val
               }
        });
          this.checkboxGroup1= [...new Set(this.checkboxGroup1)];
         })
            }
      }) 
    },
    getdate(){
      var that=this;
              this.formInline.page_config_id=String(this.$route.query.page_config_id);
             axios.get('/pc/page-config/single', {page_config_id:this.page.page_config_id}).then((v) => {
               this.formInline.page_module_id=String(v.data.page_config.page_module_id)
             this.init()
         this.formInline.company_type_id=String(v.data.page_config.company_type_id);
         this.formInline.company_tag_id=v.data.page_config.company_tag_id;
          this.formInline.page_config_name=v.data.page_config.page_config_name
          this.formInline.page_module_name=v.data.page_config.page_module_name
          this.formInline.company_tag_name=v.data.page_config.company_tag_name
          this.formInline.company_type_name=v.data.page_config.company_type_name
          aa=JSON.parse(v.data.page_config.page_config_content)
            this.formInline.page_config_content=this.formInline.page_config_content.concat(aa)
   this.formInline.page_config_content= [...new Set(this.formInline.page_config_content)];
          axios.get('/pc/company-type/all','').then((v) => {
          this.list_btn1=v.data.company_type_list;; 
          this.list_btn1.forEach((item,index)=>{
          if(item.company_type_id==this.formInline.company_type_id){
            this.activeClass1=index
          }
           })
         
         axios.get('/pc/company-tag/all',{company_type_id:this.formInline.company_type_id}).then((v) => {
          this.list_btn=v.data.company_tag_list;;
            this.list_btn.forEach((item,index)=>{
          if(item.company_tag_id==this.formInline.company_tag_id){
            this.activeClass=index
          }
        })
         }) 
          })
      })
    },
    init1(){
       axios.get('/pc/company-type/all','').then((v) => {
          this.list_btn1=v.data.company_type_list;
          this.formInline.company_type_id=this.list_btn1[0].company_type_id;
          this.formInline.company_type_name=this.list_btn1[0].company_type_name
        this.init2(this.formInline.company_type_id);
       
      })
    },
    init2(paramas){
        axios.get('/pc/company-tag/all',{company_type_id:String(paramas)}).then((v) => {
          this.list_btn=v.data.company_tag_list;
            if(this.page.page_config_id){
            // this.getdate()
          }else{
           this.formInline.company_tag_id=this.list_btn[0].company_tag_id;
           this.formInline.company_tag_name=this.list_btn[0].company_tag_name;
          }
         
      })
    },
     getModule(params){
         axios.get('/pc/page-module/all').then((v) => {   
             this.statusOptions=v.data.page_module_list;
      })
    },
    aaa(index,val){
       const regex = /^(0(\.\d{1})?|1(\.0{1})?)$/;
        if(!regex.test(val)) {
             this.$message.error('只能输入0到1之间的一位小数（包括0和1）');
        }
         this.formInline.page_config_content.forEach(item=>{
           if(item.attr_en==index){
             item.attr_val=val
           }
         })
    },
    change(val){
          this.formInline.page_module_id=String(val);
           var obj = {}
      obj = this.statusOptions.find(item=>{
        return item.page_module_id == val;
      })
       this.formInline.page_module_name=obj.page_module_name
           this.init()
    },
     IsActive1(k,val,val1) {
        this.activeClass1 = k;
        this.formInline.company_type_id=String(val);
        this.formInline.company_type_name=val1;
        this.init2(val);
      },
     IsActive(k,val,val1) {
        this.activeClass = k;
        this.formInline.company_tag_id=String(val);
        this.formInline.company_tag_name=val1;
      },
    handleCheckedChange(val){
      val= [...new Set(val)];
        this.list=[];
        this.checkboxGroup2=[];
         this.formInline.page_config_content=[];
      this.list=this.list.concat(this.checkboxGroup2).concat(val)
       this.list= [...new Set(this.list)];
         this.list1.forEach(item1=>{
             this.list.forEach(item2=>{
           if(item1.attr_en==item2){
              this.formInline.page_config_content.push(objKeySort({
             attr_en:item1.attr_en,
               attr_ch:item1.attr_ch,
               attr_val:item1.attr_val,
               attr_img:''
          }))
           }
         
             })
      })
      this.formInline.page_config_content= [...new Set(this.formInline.page_config_content)];
      
    },
     submitForm(formInline){
          let paramas=this.formInline
          let paramas1={
            company_tag_id:String(paramas.company_tag_id),
            company_type_id:String(paramas.company_type_id),
            page_config_name:paramas.page_config_name,
            page_config_id:String(paramas.page_config_id),
            page_module_id:String(paramas.page_module_id)
          }
         this.$refs[formInline].validate((valid) => {
        if (valid) {
          let aaaa=0;
           if(paramas.page_module_id==5){
             paramas.page_config_content.forEach((item,index)=>{
            if(item.attr_val==''){
              this.$message.error('所选属性必须有属性值');
              aaaa=1;
              return false;
            }else{
              const regex = /^(0(\.\d{1})?|1(\.0{1})?)$/;
                if(!regex.test(item.attr_val)) {
             this.$message.error('只能输入0到1之间的一位小数（包括0和1）');
              aaaa=1;
              return false;
        }
            }
          })
          }else{
            paramas.page_config_content.forEach((item,index)=>{
              const regex = /^(0(\.\d{1})?|1(\.0{1})?)$/;
              if(item.attr_val){
                if(!regex.test(item.attr_val)) {
             this.$message.error('只能输入0到1之间的一位小数（包括0和1）');
              aaaa=1;
              return false;
                }
              }
                
          })
          }
          if(aaaa==0){
            // axios.get("/pc/page-config/exist", paramas1).then(v => { 
                   if(this.page.page_config_id){
                   axios.put('/pc/page-config/update', paramas).then((v) => {
      this.$router.back()
      })
              }else{
                axios.post('/pc/page-config/save', paramas).then((v) => {
      this.$router.back()
      })
              }              
                //  });
          }   
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
   overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    .active {
       background: #409eff;
    border-color: #409eff;
    color: #FFFFFF;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  .patroladd-title1{height: 34px;line-height: 34px;}
.el-checkbox{    display: flex;
    justify-content: left;
    align-items: center;}

}

</style>
